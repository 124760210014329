import deepParseJson from 'utils/deepParseJson';
import { PERSIST_STORE_NAME } from 'constants/app.constant';
import { USER, ADMIN } from 'constants/roles.constant';

export function getAuthTokens() {
  const rawPersistData = localStorage.getItem(PERSIST_STORE_NAME);
  const persistData = deepParseJson(rawPersistData);
  const { access, refresh } = persistData.auth.session;

  return {
    access,
    refresh,
  };
}

export function getUserAthority(role) {
  if (role === 'admin') {
    return [ADMIN, USER];
  } else {
    return [USER];
  }
}
