import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  currentRouteKey: '',
  shopDB: 'at',
};

export const commonSlice = createSlice({
  name: 'base/common',
  initialState,
  reducers: {
    setCurrentRouteKey: (state, action) => {
      state.currentRouteKey = action.payload;
    },
    setShopDB: (state, action) => {
      state.shopDB = action.payload;
    },
  },
});

export const { setCurrentRouteKey, setShopDB } = commonSlice.actions;

export default commonSlice.reducer;
