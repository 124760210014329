import axios from 'axios';
import { Notification, toast } from 'components/ui';
import history from '../../history';
import store from 'store';
import { getAuthTokens } from 'utils/auth';
import { onSignOutSuccess, setAccessToken } from 'store/auth/sessionSlice';
import { setUser, initialState } from 'store/auth/userSlice';
import apiConfig from 'configs/api.config';
import appConfig from 'configs/app.config';

const ACCOUNTS_AUTH_TOKEN_REFRESH_API_ENDPOINT = 'accounts/auth/token/refresh/';
const httpStatusCodeMessageMap = {
  500: 'Internal Server Error',
  400: 'Bad Request',
  404: 'Page Not Found',
};

let isAlreadyRefreshingAccessToken = false;
let requestQueue = [];

function callRequestFromQueue(token) {
  requestQueue.map((request) => request(token));
}

function addRequestToQueue(request) {
  requestQueue.push(request);
}

class APIClient {
  /**
   * Axios wrapper client:
   * 1. Adds an access token before each request
   * 2. On response checks if the token is expired:
   *  1. If expired, tries to refetch a new one:
   *    1. If the fetch was successful:
   *      1. Sets the new token in the authorization header
   *      2. Performs retransmission of each failed request
   *    2. If an error occurred:
   *      1. Removes the tokens from the local storage
   *      2. Resets the Redux state
   *      3. Redirects to the login page
   */
  constructor() {
    this.client = axios.create({
      baseURL: apiConfig.BACKEND_API_BASE,
    });
    this.refreshClient = axios.create({
      baseURL: apiConfig.BACKEND_API_BASE,
    });
    // Register axios interceptors to intercept requests or responses
    // before they are handled by axios
    this.setUpAxiosInterceptors();
  }

  setUpAxiosInterceptors() {
    this.client.interceptors.request.use(
      (request) => this.constructor.preRequestHandler(request),
      (error) => this.constructor.errorRequestHandler(error),
    );

    this.client.interceptors.response.use(
      (response) => this.constructor.successResponseHandler(response),
      (error) => this.errorResponseHandler(error),
    );
  }

  static preRequestHandler(request) {
    const accessToken = getAuthTokens().access;
    if (accessToken) {
      request.headers.authorization = `Bearer ${accessToken}`;
    }
    return request;
  }

  static errorRequestHandler(error) {
    return Promise.reject(error);
  }

  static successResponseHandler(response) {
    return response;
  }

  errorResponseHandler(error) {
    const { response } = error;
    const {
      status,
      config: { baseURL, url },
    } = response;
    const originalRequest = error.config;
    const refreshToken = getAuthTokens().refresh;
    const errorMessage = httpStatusCodeMessageMap[status] || response.statusText;

    // Check if the request has failed (Unauthorized access) and the refresh token exists on the local storage
    if (status === 401 && refreshToken) {
      // Set the isAlreadyRefreshingAccessToken flag to true on the first failed request
      // and try to refetch a new access token
      if (!isAlreadyRefreshingAccessToken) {
        isAlreadyRefreshingAccessToken = true;
        // When promise resolves with a new token, run all queued up requests from the queue with the new token
        this.refreshAccessToken(refreshToken)
          .then((accessToken) => {
            isAlreadyRefreshingAccessToken = false;
            callRequestFromQueue(accessToken);
            store.dispatch(setAccessToken(accessToken));
            requestQueue = []; // And reset the queue
          })
          // If an error occurs, forcibly log the user out
          .catch((error) => {
            isAlreadyRefreshingAccessToken = false;
            requestQueue = [];
            this.constructor.forceLogout();
          });
      }

      // Create a new promise that will retry the failed request (with old config and a new access token)
      const retryRequest = new Promise((resolve) => {
        addRequestToQueue((accessToken) => {
          originalRequest.headers.authorization = `Bearer ${accessToken}`;
          resolve(this.client(originalRequest));
        });
      });

      return retryRequest;
    }

    if (status === 500) {
      toast.push(
        <Notification closable title={`500 - ${errorMessage} at:`} type="danger" duration={7500}>
          {`${baseURL}${url}`}
        </Notification>,
        {
          placement: 'top-end',
        },
      );
    }

    if (status === 400 || status === 404) {
      toast.push(
        <Notification
          closable
          title={`${status} - ${errorMessage} at:`}
          type="danger"
          duration={7500}
        >
          {`${baseURL}${url}`}
        </Notification>,
        {
          placement: 'top-end',
        },
      );
    }

    return Promise.reject(error);
  }

  async refreshAccessToken(refreshToken) {
    try {
      /**
       * If a refresh token is invalid (expired or intentionally changed on the client side),
       * Django server returns 401 in the response-
       */
      const response = await this.refreshClient.post(ACCOUNTS_AUTH_TOKEN_REFRESH_API_ENDPOINT, {
        refresh: refreshToken,
      });
      return response.data.access;
    } catch (error) {
      throw new Error(error);
    }
  }

  static forceLogout() {
    store.dispatch(onSignOutSuccess());
    store.dispatch(setUser(initialState));
    history.push(appConfig.unAuthenticatedEntryPath);
  }

  get(...args) {
    return this.client.get(...args);
  }

  post(...args) {
    return this.client.post(...args);
  }

  options(...args) {
    return this.client.options(...args);
  }

  put(...args) {
    return this.client.put(...args);
  }

  patch(...args) {
    return this.client.patch(...args);
  }

  delete(...args) {
    return this.client.delete(...args);
  }
}

export default APIClient;
