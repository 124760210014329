import { useSelector, useDispatch } from 'react-redux';
import { Notification, toast } from 'components/ui';
import { setUser, initialState } from 'store/auth/userSlice';
import { onSignInSuccess, onSignOutSuccess } from 'store/auth/sessionSlice';
import appConfig from 'configs/app.config';
import { REDIRECT_URL_KEY } from 'constants/app.constant';
import { useNavigate } from 'react-router-dom';
import accountsAPIClient from 'api/clients/accounts';
import useQuery from './useQuery';
import { getUserAthority } from 'utils/auth';

function useAuth() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const query = useQuery();
  const { access, signedIn } = useSelector((state) => state.auth.session);

  const signIn = async (credentials) => {
    try {
      let response;
      try {
        response = await accountsAPIClient.createAuthTokens(credentials);
      } catch (error) {
        handleSignOut();
        return {
          status: 'failed',
          message: error?.response?.data?.detail || error.toString(),
        };
      }
      if (response.data) {
        const { access, refresh } = response.data;
        dispatch(onSignInSuccess({ access, refresh }));
        // TODO: return back or jwt decode + BE changes
        const meResponse = await accountsAPIClient.getUserInformation({
          headers: {
            authorization: `Bearer ${access}`,
          },
        });
        const { username, email, role } = meResponse.data;
        dispatch(
          setUser({
            avatar: '/img/avatars/A0.png',
            userName: username,
            authority: getUserAthority(role),
            email: email,
          }),
        );
        const redirectUrl = query.get(REDIRECT_URL_KEY);
        navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath);
        toast.push(
          <Notification closable title={'Logged In'} type="success" duration={7500}>
            You have successfully logged in
          </Notification>,
          {
            placement: 'top-end',
          },
        );
        return {
          status: 'success',
          message: '',
        };
      }
    } catch (error) {
      handleSignOut();
      throw new Error(error.toString());
    }
  };

  const handleSignOut = () => {
    dispatch(onSignOutSuccess());
    dispatch(setUser(initialState));
    navigate(appConfig.unAuthenticatedEntryPath);
  };

  const signOut = async () => {
    // await apiSignOut();
    handleSignOut();
  };

  return {
    authenticated: access && signedIn,
    signIn,
    signOut,
  };
}

export default useAuth;
