import { createSlice } from '@reduxjs/toolkit';

export const sessionSlice = createSlice({
  name: 'auth/session',
  initialState: {
    access: '',
    refresh: '',
    signedIn: false,
  },
  reducers: {
    onSignInSuccess: (state, action) => {
      state.signedIn = true;
      state.access = action.payload.access;
      state.refresh = action.payload.refresh;
    },
    onSignOutSuccess: (state) => {
      state.signedIn = false;
      state.access = '';
      state.refresh = '';
    },
    setAccessToken: (state, action) => {
      state.access = action.payload;
    },
  },
});

export const { onSignInSuccess, onSignOutSuccess, setAccessToken } = sessionSlice.actions;

export default sessionSlice.reducer;
