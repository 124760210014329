import store from 'store';

export function buildPath(path, params = {}) {
  if (typeof path !== 'string' || path instanceof String)
    throw new Error('Path should be of String type!');
  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      path = path.replace(`:${key}`, params[key]);
    }
  }
  const match = path.match(/:(\w*)/g);
  if (match) {
    throw Error(`Path parameter(s) [${match}] is (are) required!`);
  }
  return path;
}

export function getNonFalseyQueryParams(queryParams) {
  const nonFalseyQueryParams = {};
  for (const [key, value] of Object.entries(queryParams)) {
    if (value) {
      nonFalseyQueryParams[key] = value;
    }
  }
  return nonFalseyQueryParams;
}

export function buildQueryString(params = {}) {
  const nonFalseyParams = getNonFalseyQueryParams(params);
  const query = [];
  for (const key in nonFalseyParams) {
    if (nonFalseyParams.hasOwnProperty(key)) {
      query.push(`${key}=${nonFalseyParams[key]}`);
    }
  }
  return query.join('&');
}

export function buildUrl(path, { pathParams = {}, queryParams = {} } = {}) {
  path = path.replace(':db', store.getState().base.common.shopDB);
  path = buildPath(path, pathParams);
  const query = buildQueryString(queryParams);
  if (query.length > 0) return `${path}?${query}`;
  return path;
}
